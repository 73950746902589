import { useLoaderData } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import ItemInfoSport from "../../components/ItemInfo/ItemInfoSport";
import SportItemsCarousel from "../../components/ItemsCarousel/SportItemsCarousel";
import { fetchSportRelatedItems } from "../../functions/fetch";

function ItemSport(params) {
    const { department, category, item } = useLoaderData();
    const breadcrumbPath = ["sport", department];

    if (category != null) {
        breadcrumbPath.push(category);
    }

    return (
        <>
            <Breadcrumbs path={breadcrumbPath} />
            <ItemInfoSport item={item} />
            <SportItemsCarousel
                title={"Related Products"}
                items={fetchSportRelatedItems()}
            />
        </>
    );
}

export default ItemSport;
