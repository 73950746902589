import { useNavigate } from "react-router-dom";
import { fetchPathByCategoryName } from "../../functions/fetch";
import { Heart3SVG } from "../../images/SVGAssets";

function ItemCardSport(params) {
    const navigate = useNavigate();

    return (
        <div
            className="flex flex-col min-w-[160px] h-[250px] relative cursor-pointer"
            onClick={() =>
                navigate(
                    `/sport/${fetchPathByCategoryName(
                        params.department
                    )}/${fetchPathByCategoryName(params.category)}/${params.id}`
                )
            }
        >
            <div className="h-[170px]">
                <img className="h-full" src={params.image} alt={params.title} />
            </div>
            <div className="flex flex-col py-3">
                <p className="font-secondary text-sm not-italic font-semibold leading-5 text-blue-lidabro-header">
                    ₪ {params.price}
                </p>
                <p className="font-secondary text-xs not-italic font-medium leading-[18px] text-black-lidabro">
                    {params.title}
                </p>
                <p className="font-secondary text-xs not-italic font-medium leading-[18px] text-gray-lidabro-dark">
                    {params.type}
                </p>
            </div>
            <div className="flex flex-col absolute top-[10px] right-[11px]">
                <Heart3SVG />
            </div>
        </div>
    );
}

export default ItemCardSport;
