import { useLoaderData } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import ItemsFilters from "../../components/ItemsFilters/ItemsFilters";
import BannerItemsSport from "../../components/Banner/BannerItemsSport";
import Subcategory from "../../components/Category/Subcategory";
import ControlPanelSport from "../../components/ControlPanel/ControlPanelSport";
import ItemsList from "../../components/ItemsList/ItemsList";
import {
    fetchItemsByCategory,
    fetchItemsByDepartment,
} from "../../functions/fetch";

function ItemsSport(params) {
    const { department, category } = useLoaderData();
    const breadcrumbPath = ["sport", department];
    const items =
        category != null
            ? fetchItemsByCategory(category)
            : fetchItemsByDepartment(department);

    if (category != null) {
        breadcrumbPath.push(category);
    }

    return (
        <>
            <Breadcrumbs path={breadcrumbPath} />
            <div className="flex">
                <div className="hidden md:flex md:min-w-[200px] lg:min-w-[300px]">
                    <ItemsFilters
                        category={breadcrumbPath[breadcrumbPath.length - 1]}
                    />
                </div>
                <div className="overflow-auto flex-grow">
                    <BannerItemsSport
                        category={breadcrumbPath[breadcrumbPath.length - 1]}
                    />
                    {category == null ? (
                        <Subcategory department={department} />
                    ) : null}
                    <ControlPanelSport amount={items.length} />
                    <ItemsList items={items} />
                </div>
            </div>
        </>
    );
}

export default ItemsSport;
