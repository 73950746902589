import { ChevronRightSVG } from "../../images/SVGAssets";
import { fetchCategoryNameByPath } from "../../functions/fetch";

function Breadcrumb({ path, last }) {
    const label = fetchCategoryNameByPath(path);
    return (
        <>
            <div className="flex sm:hidden items-center">
                <p className="font-secondary text-base not-italic font-semibold leading-6">
                    {label}
                </p>
            </div>

            <div className="hidden sm:flex items-center">
                <p
                    className={`px-2 py-1 cursor-pointer${
                        last
                            ? " text-gray-lidabro-breadcrumb-bold font-semibold"
                            : ""
                    }`}
                >
                    {label}
                </p>
                {!last ? <ChevronRightSVG /> : null}
            </div>
        </>
    );
}

export default Breadcrumb;
