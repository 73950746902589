import Slider from "../Slider/Slider";
import StreamSeeLive from "../Stream/StreamSeeLive";
import ItemVariants from "../ItemVariants/ItemVariants";
import ItemVariantsDropdown from "../ItemVariants/ItemVariantsDropdown";
import ItemSeller from "../ItemSeller/ItemSeller";
import ItemDescriptionSport from "../ItemDescription/ItemDescriptionSport";
import ItemDetailsSport from "../ItemDetails/ItemDetailsSport";
import StreamSeeLiveQuestions from "../Stream/StreamSeeLiveQuestions";
import ImageCarousel from "../Carousel/ImageCarousel";
import ButtonIcon from "../Controls/ButtonIcon";
import ItemAddToBasker from "../ItemAddToBasket/ItemAddToBasker";
import { HeartSVG, ShareSVG } from "../../images/SVGAssets";

function ItemInfoSport({ item }) {
    //TODO: Stock

    return (
        <>
            <div className="flex sm:hidden flex-col px-6 py-3 gap-4">
                <div className="flex flex-col">
                    <p className="font-secondary text-sm not-italic font-normal leading-5 text-black">
                        {item.brand}
                    </p>
                    <p className="font-secondary text-base not-italic font-semibold leading-6">
                        {item.title}
                    </p>
                </div>
                <Slider images={item.image} />
                <div className="flex gap-2">
                    <p className="font-main text-2xl not-italic font-semibold leading-8 text-gray-lidabro-breadcrumb">
                        ₪ {item.price}
                    </p>
                    <p className="font-secondary text-xl not-italic font-medium leading-[30px] text-green-lidabro-text">
                        In Stock
                    </p>
                </div>
                <StreamSeeLive eyezon={item.eyezon} />
                <ItemVariants variants={item.variants} />
                <ItemSeller seller={item.seller} />
                <ItemDescriptionSport item={item} />
                <ItemDetailsSport item={item} />
                <StreamSeeLiveQuestions eyezon={item.eyezon} />
            </div>

            <div className="hidden sm:flex md:hidden flex-col px-12 pt-6 pb-12 gap-8">
                <ImageCarousel images={item.image} />
                <div className="flex flex-col p-[25px] rounded-[20px] gap-4 bg-gray-lidabro-select-background">
                    <div className="flex items-center justify-between">
                        <div className="flex flex-col">
                            <p className="font-secondary text-lg not-italic font-semibold leading-7 text-black-lidabro-select-text">
                                {item.brand}
                            </p>
                            <p className="font-secondary text-lg not-italic font-semibold leading-7 text-black-lidabro-select-text">
                                {item.title}
                            </p>
                            <p className="font-secondary text-sm not-italic font-medium leading-5 text-gray-lidabro-article">
                                #{item.id}
                            </p>
                        </div>
                        <div className="flex">
                            <ButtonIcon icon={<HeartSVG />} />
                            <ButtonIcon icon={<ShareSVG />} />
                        </div>
                    </div>
                    <div className="flex gap-2">
                        <p className="font-main text-2xl not-italic font-semibold leading-8 text-gray-lidabro-text">
                            ₪ {item.price}
                        </p>
                        <p className="font-secondary text-xl not-italic font-medium leading-[30px] text-green-lidabro-text">
                            In Stock
                        </p>
                    </div>
                    <StreamSeeLive eyezon={item.eyezon} />
                    <ItemVariants variants={item.variants} />
                    <ItemSeller seller={item.seller} />
                    <ItemDescriptionSport item={item} />
                    <ItemDetailsSport item={item} />
                    <StreamSeeLiveQuestions eyezon={item.eyezon} />
                </div>
            </div>

            <div className="hidden md:flex flex-col">
                <div className="flex flex-wrap xmd:flex-nowrap items-center justify-center gap-8 px-12 lg:px-[60px] xl:px-[150px] pt-6 pb-12 lg:pb-6">
                    <div className="w-full min-w-full flex xmd:hidden">
                        <Slider images={item.image} />
                    </div>
                    <div className="hidden xmd:flex xl:hidden grow max-w-full overflow-auto">
                        <ImageCarousel
                            images={item.image}
                            direction={"bottom"}
                        />
                    </div>
                    <div className="hidden xl:flex grow max-w-full overflow-auto">
                        <ImageCarousel images={item.image} direction={"left"} />
                    </div>
                    <div className="max-w-[350px] lg:max-w-[480px] flex grow-0 flex-col items p-[25px] gap-4 bg-gray-lidabro-select-background rounded-[20px]">
                        <div className="flex items-center justify-between">
                            <div className="flex flex-col">
                                <p className="font-secondary text-lg not-italic font-semibold leading-7 text-black-lidabro-select-text">
                                    {item.brand}
                                </p>
                                <p className="font-secondary text-lg not-italic font-semibold leading-7 text-black-lidabro-select-text">
                                    {item.title}
                                </p>
                                <p className="font-secondary text-sm not-italic font-medium leading-5 text-gray-lidabro-article">
                                    #{item.id}
                                </p>
                            </div>
                            <div className="flex">
                                <ButtonIcon icon={<HeartSVG />} />
                                <ButtonIcon icon={<ShareSVG />} />
                            </div>
                        </div>
                        <div className="flex gap-2">
                            <p className="font-main text-2xl not-italic font-semibold leading-8 text-gray-lidabro-text">
                                ₪ {item.price}
                            </p>
                            <p className="font-secondary text-xl not-italic font-medium leading-[30px] text-green-lidabro-text">
                                In Stock
                            </p>
                        </div>
                        <StreamSeeLive eyezon={item.eyezon} />
                        <ItemVariantsDropdown variants={item.variants} />
                        <ItemAddToBasker />
                    </div>
                </div>

                <div className="flex justify-center px-12 py-10 bg-gray-lidabro-select-background">
                    <div className="flex flex-col gap-6 px-10 py-12 rounded-[25px] bg-white">
                        <ItemSeller
                            seller={item.seller}
                            background={"bg-gray-lidabro-select-background"}
                        />
                        <ItemDescriptionSport item={item} collapsable={false} />
                        <ItemDetailsSport item={item} />
                        <StreamSeeLiveQuestions eyezon={item.eyezon} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default ItemInfoSport;
