function ItemVariant({ variant }) {
    return (
        <>
            <button className="flex items-center justify-center px-6 py-[10px] border border-gray-lidabro-border-3 rounded-lg font-secondary text-base font-normal leading-6 text-gray-lidabro-placeholder bg-white">
                {variant.value}
            </button>
        </>
    );
}

export default ItemVariant;
