import Counter from "../Controls/Counter";
import ButtonIcon from "../Controls/ButtonIcon";
import { ShoppingBag2SVG } from "../../images/SVGAssets";

function ItemAddToBasker(params) {
    return (
        <div className="flex gap-4">
            <Counter />
            <div className="flex grow rounded-lg bg-gray-lidabro-breadcrumb">
                <ButtonIcon icon={<ShoppingBag2SVG />} />
            </div>
        </div>
    );
}

export default ItemAddToBasker;
