import { useState } from "react";
import { Outlet } from "react-router-dom";
import NavMenuSport from "../components/NavMenu/NavMenuSport";
import NavBarSport from "../components/NavBar/NavBarSport";
import FooterSport from "../components/Footer/FooterSport";
import BasketSport from "../components/Basket/BasketSport";

function SportContainer(params) {
    const [isBasketOpen, setIsBasketOpen] = useState(false);
    const [isFavoriteOpen, setIsFavoriteOpen] = useState(false);

    const toggleBasket = () => {
        setIsBasketOpen(!isBasketOpen);
    };

    const toggleFavorite = () => {
        setIsFavoriteOpen(!isFavoriteOpen);
    };

    return (
        <>
            <NavMenuSport
                actions={{
                    basket: setIsBasketOpen,
                    favorite: setIsFavoriteOpen,
                }}
            />
            <NavBarSport />
            <main className="relative">
                {isBasketOpen && (
                    <div className="max-w-full absolute flex items-center justify-center bg-black bg-opacity-50 z-10">
                        <BasketSport closeBasket={toggleBasket} />
                    </div>
                )}
                <Outlet />
            </main>

            <FooterSport />
        </>
    );
}

export default SportContainer;
