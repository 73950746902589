import BannerSport from "../../components/Banner/BannerSport";
import Subcategory from "../../components/Category/Subcategory";
import SportItemsCarousel from "../../components/ItemsCarousel/SportItemsCarousel";
import CuratedCollection from "../../components/CuratedCollection/CuratedCollection";
import BrandCarousel from "../../components/Carousel/BrandCarousel";
import {
    fetchSportNewItems,
    fetchSportRelatedItems,
} from "../../functions/fetch";

function Sport(params) {
    return (
        <>
            <BannerSport />
            <Subcategory />
            <SportItemsCarousel
                title={"New Comings"}
                items={fetchSportNewItems()}
            />
            <CuratedCollection />
            <BrandCarousel />
            <SportItemsCarousel
                title={"Related Products"}
                items={fetchSportRelatedItems()}
            />
        </>
    );
}

export default Sport;
