import { ShopSVG } from "../../images/SVGAssets";

function ItemSeller({ seller, background }) {
    return (
        <>
            <div
                className={`flex justify-between p-4 rounded-lg ${
                    background ? background : "bg-white"
                }`}
            >
                <div className="flex gap-2">
                    <ShopSVG />
                    <p className="font-secondary text-base font-medium leading-6 text-gray-lidabro-breadcrumb">
                        Sold by {seller.displayName}
                    </p>
                </div>
            </div>
        </>
    );
}

export default ItemSeller;
