import { useState } from "react";

function Slider(params) {
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToSlide = (index) => {
        setCurrentIndex(index);
    };

    return (
        <div className="relative w-full flex flex-col justify-center items-end self-stretch gap-[5px] p-3">
            <div className="flex grow w-full max-h-[50vh]">
                <img
                    className="min-h-[250px] w-full object-cover"
                    src={params.images[currentIndex].imageUrl}
                    alt={params.images[currentIndex].alt}
                />
            </div>
            <div className="flex gap-3 absolute left-[22px] top-[22px]">
                {params.images.map((image, index) => (
                    <span
                        key={index}
                        className={`inline-block w-2 h-2 rounded-full ${
                            index === currentIndex
                                ? "bg-blue-lidabro-button"
                                : "bg-gray-lidabro-border-3"
                        }`}
                        onClick={() => goToSlide(index)}
                    />
                ))}
            </div>
        </div>
    );
}

export default Slider;
