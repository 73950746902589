import { useRef } from "react";
import { ArrowLeft2SVG, ArrowRight2SVG } from "../../images/SVGAssets";
import ItemCardSport from "../ItemCard/ItemCardSport";
import { useIsOverflow } from "../../hook/useIsOverflow";

function SportItemsCarousel(params) {
    const scrollContainerRef = useRef(null);
    const isOverflow = useIsOverflow(scrollContainerRef);

    const scrollLeft = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({
                left: -160,
                behavior: "smooth",
            });
        }
    };

    const scrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({
                left: 160,
                behavior: "smooth",
            });
        }
    };

    return (
        <div className="flex flex-col px-3 py-[10px] sm:px-12 sm:py-[5px] md:px-12 md:py-10 lg:px-[60px] xl:px-[180px] border-b border-gray-lidabro-border-2">
            <div className="flex px-[10px] md:px-0 items-center justify-between">
                <h4 className="font-secondary text-sm md:text-lg not-italic font-semibold md:font-medium leading-5 md:leading-7 text-black-lidabro">
                    {params.title}
                </h4>
                {isOverflow && (
                    <div className="flex items-center">
                        <div
                            className="flex px-3 py-[10px] items-center cursor-pointer"
                            onClick={scrollLeft}
                        >
                            <ArrowLeft2SVG />
                        </div>
                        <div
                            className="flex px-3 py-[10px] items-center cursor-pointer"
                            onClick={scrollRight}
                        >
                            <ArrowRight2SVG />
                        </div>
                    </div>
                )}
            </div>
            <div
                className="flex justify-between gap-[18px] overflow-x-auto scrollbar-none"
                ref={scrollContainerRef}
            >
                {params.items.map((item) => (
                    <ItemCardSport
                        key={item.id}
                        price={item.price}
                        title={item.title}
                        type={item.type}
                        image={item.image}
                        onClick={() => alert("clicked!")}
                    />
                ))}
            </div>
        </div>
    );
}

export default SportItemsCarousel;
