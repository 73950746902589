import SingleChoice from "../SellerCatalog/SingleChoice";

function ItemVariantsDropdown(params) {
    return (
        <>
            <SingleChoice values={params.variants} />
        </>
    );
}

export default ItemVariantsDropdown;
