import { useNavigate } from "react-router-dom";
import CategoryBadge from "./CategoryBadge";
import {
    fetchAllDepartmentsCaterogies,
    fetchDepartmentCaterogies,
} from "../../functions/fetch";

function Subcategory(params) {
    const navigate = useNavigate();

    const subcategories = params.department
        ? fetchDepartmentCaterogies(params.department)
        : fetchAllDepartmentsCaterogies();

    function getJsonByDepartment(data) {
        const groupedData = data.reduce((acc, item) => {
            const department = item.department;

            if (!acc[department]) {
                acc[department] = [];
            }

            acc[department].push(item);

            return acc;
        }, {});

        const result = Object.keys(groupedData).map((department) => ({
            department,
            items: groupedData[department],
        }));

        return result;
    }

    return (
        <>
            <div className="flex md:hidden p-3 gap-3 overflow-x-auto scrollbar-none">
                {subcategories.map((category) => (
                    <CategoryBadge
                        key={category.id}
                        label={category.name}
                        icon={category.icon}
                        onClick={() => alert("clicked!")}
                    />
                ))}
            </div>

            <div className="hidden md:flex lg:hidden flex-col px-12 py-10 gap-[18px] border-b border-gray-lidabro-border-2">
                <p className="font-secondary text-lg not-italic font-medium leading-7">
                    Find what you need
                </p>
                <div className="flex items-center justify-center gap-3  flex-wrap">
                    {subcategories.map((category) => (
                        <CategoryBadge
                            key={category.id}
                            label={category.name}
                            icon={category.icon}
                            onClick={() => alert("clicked!")}
                        />
                    ))}
                </div>
            </div>

            <div className="hidden lg:flex flex-col gap-[18px] px-[60px] xl:px-[120px] py-10 border-b border-gray-lidabro-border-2">
                {getJsonByDepartment(subcategories).map((department) => (
                    <div className="flex flex-col xl:flex-row xl:items-center gap-[18px] xl:gap-0">
                        <p className="font-secondary text-lg not-italic font-medium leading-7 text-black-lidabro xl:min-w-[250px]">
                            {department.department}
                        </p>
                        <div className="flex gap-3 overflow-x-auto scrollbar-none">
                            {department.items.map((category) => (
                                <CategoryBadge
                                    key={category.id}
                                    label={category.name}
                                    icon={category.icon}
                                    onClick={() => navigate(category.url)}
                                />
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}

export default Subcategory;
