import axios from "axios";
const SERVER = getServer();

function getServer() {
    switch (process.env.NODE_ENV) {
        case "development":
            return "http://localhost:4000";
        case "stage":
            return process.env.SERVER_STAGE;
        case "production":
            return process.env.SERVER_PRODUCTION;
    }
}

export function fetchNavCategories() {
    let categoriesAmount = 6;
    let categories = [];
    for (let i = 1; i < categoriesAmount; i++) {
        let category = { key: `Category${i}`, name: `Suggestion ${i}` };
        categories.push(category);
    }
    return categories;
}

export function fetchSetupList() {
    const setupList = [];
    setupList.push({
        id: "001",
        name: "About",
        description: "Add Information about the shop",
    });
    setupList.push({
        id: "002",
        name: "Members",
        description: "Add Team Members and Streamers",
    });
    setupList.push({
        id: "003",
        name: "Stream",
        description: "Set Stream Settings",
    });
    setupList.push({
        id: "004",
        name: "Bank",
        description: "Add Bank Account",
    });

    return setupList;
}

export function fetchSetupSubList(listId) {
    const setupList = [];
    switch (listId) {
        case "001":
            setupList.push({
                id: "006",
                name: "Description",
                description: "Write shop's description",
                isDone: "true",
            });
            setupList.push({
                id: "007",
                name: "Logo",
                description: "Upload Logo",
                isDone: "false",
            });
            setupList.push({
                id: "008",
                name: "PickUpPoint",
                description: "Set up pickup point",
                isDone: "false",
            });
            setupList.push({
                id: "009",
                name: "Streaming",
                description: "Set Streaming Hours",
                isDone: "false",
            });
        case "002":
            setupList.push({
                id: "010",
                name: "InviteEditors",
                description:
                    "Invite Team Members to collaborate and assist in keeping your shop up-tp-date",
                isDone: "false",
            });
            setupList.push({
                id: "011",
                name: "InviteStreamers",
                description:
                    "Add performers who will showcase catalog assortment during live streams",
                isDone: "false",
            });
        case "003":
            setupList.push({
                id: "012",
                name: "Language",
                description:
                    "Add more language to attract larger customer range",
                isDone: "false",
            });
            setupList.push({
                id: "013",
                name: "InitialExperience",
                description: "Create more personalised service",
                isDone: "false",
            });
            setupList.push({
                id: "015",
                name: "Widget",
                description: "Set up customer interaction tools",
                isDone: "false",
            });
        case "004":
            setupList.push({
                id: "016",
                name: "BankAccount",
                description:
                    "Link your bank account to enable us to deposit the earnings from your sold items directly",
                isDone: "false",
            });
    }

    return setupList;
}

export function fetchCreateShopCurrentStep() {
    return 1;
}

export async function fetchItemByName(id, signal) {
    categoriesAmoun = 5;
    categories = [];
    for (let i = 1; i < categoriesAmoun; i++) {
        category = { key: `Category${i}`, category: `Category ${i}` };
        categories.push(category);
    }
}

export async function signUp(data, controller) {
    const searchObject = {
        api: SERVER,
        endpoint: `users/`,
    };
    const url = new URL(`${searchObject.endpoint}`, `${searchObject.api}`);

    data.controller = controller;
    try {
        const response = await axios.post(url.href, data);
        return response;
    } catch (err) {
        throw err;
    }
}

export async function signIn(data) {
    const searchObject = {
        api: SERVER,
        endpoint: `users/signin`,
    };
    const url = new URL(`${searchObject.endpoint}`, `${searchObject.api}`);
    try {
        const response = await axios.post(url.href, data);
        return response;
    } catch (err) {
        throw err;
    }
}

export async function isShopCreated(userId) {
    const searchObject = {
        api: SERVER,
        endpoint: `shops/user/${userId}`,
    };
    const url = new URL(`${searchObject.endpoint}`, `${searchObject.api}`);
    try {
        const response = await axios.get(url.href);
        return response;
    } catch (err) {
        throw err;
    }
}

export async function createShop(data) {
    const searchObject = {
        api: SERVER,
        endpoint: `shops/`,
    };
    const url = new URL(`${searchObject.endpoint}`, `${searchObject.api}`);
    try {
        const response = await axios.post(url.href, data);
        return response;
    } catch (err) {
        throw err;
    }
}

export async function createOwner(data) {
    const searchObject = {
        api: SERVER,
        endpoint: `persons/`,
    };
    data.role = "owner";
    const url = new URL(`${searchObject.endpoint}`, `${searchObject.api}`);
    try {
        const response = await axios.post(url.href, data);
        return response;
    } catch (err) {
        throw err;
    }
}

export async function fetchItemSearch(data) {
    const searchObject = {
        api: SERVER,
        endpoint: `items/`,
    };
    const url = new URL(`${searchObject.endpoint}`, `${searchObject.api}`);
    try {
        const response = await axios.post(url.href, data);
        return response;
    } catch (err) {
        throw err;
    }
}

export function fetchSimilarItemsByCategory(category) {
    const items = [];

    switch (category) {
        case "grills":
            items.push({
                id: "0001",
                image: "/images/itemgrills1.png",
                price: "650",
                name: "LG XL Gas Grill",
                category: "Grills",
            });
            items.push({
                id: "0002",
                image: "/images/itemgrills2.png",
                price: "650",
                name: "Electro Grill",
                category: "Grills",
            });
            items.push({
                id: "0003",
                image: "/images/itemgrills3.png",
                price: "650",
                name: "Grill Pan 25 cm",
                category: "Pan",
            });
            items.push({
                id: "0004",
                image: "/images/itemgrills4.png",
                price: "650",
                name: "Cast Iron Pan 20 cm",
                category: "Pan",
            });
            items.push({
                id: "0005",
                image: "/images/itemgrills5.png",
                price: "650",
                name: "Outdoor Open Flame Grill",
                category: "Grill",
            });
            break;
        case "household":
            items.push({
                id: "0011",
                image: "/images/itemhousehold1.png",
                price: "25",
                name: "100 Recipes on Electro Grill",
                category: "Cook Book",
            });
            items.push({
                id: "0012",
                image: "/images/itemhousehold2.png",
                price: "5",
                name: "Wooden Spatula",
                category: "Kitchen Appliances ",
            });
            items.push({
                id: "0013",
                image: "/images/itemhousehold3.png",
                price: "70",
                name: "Glass Cap 300ml",
                category: "Tableware",
            });
            items.push({
                id: "0014",
                image: "/images/itemhousehold4.png",
                price: "1370",
                name: "Vacuum Cleaner",
                category: "Home Electronics",
            });
            items.push({
                id: "0015",
                image: "/images/itemhousehold5.png",
                price: "70",
                name: "Grill Pan",
                category: "Pan",
            });
            break;
    }

    return items;
}

export function fetchSportNewItems() {
    const newItems = [
        {
            id: 1,
            price: "7",
            title: "Protein Muesli 30g",
            type: "Hazelnut",
            image: "/images/items/new/ProteinMuesli30gHazelnut.png",
        },
        {
            id: 2,
            price: "7",
            title: "Protein Muesli 30g",
            type: "Apricot",
            image: "/images/items/new/ProteinMuesli30gApricot.png",
        },
        {
            id: 3,
            price: "450",
            title: "Whey Concentrate 5Lb",
            type: "Vanilla",
            image: "/images/items/new/WheyConcentrate5LbVanilla.png",
        },
        {
            id: 4,
            price: "450",
            title: "Whey Concentrate 5Lb",
            type: "Chocolate Ice Cream",
            image: "/images/items/new/WheyConcentrate5LbChocolateIceCream.png",
        },
        {
            id: 5,
            price: "9",
            title: "Crispy Protein Bar 40g",
            type: "Milky",
            image: "/images/items/new/CrispyProteinBar40g.png",
        },
        {
            id: 6,
            price: "90",
            title: "Magnesium Magnesium + Chelate 60 caps",
            type: "No taste",
            image: "/images/items/new/MagnesiumChelate60caps.png",
        },
        {
            id: 7,
            price: "160",
            title: "BB Dumbbell T-Back",
            type: "Black/Red",
            image: "/images/items/new/BBDumbbellT-Back.png",
        },
        {
            id: 8,
            price: "280",
            title: "MY RULES 609",
            type: "Black",
            image: "/images/items/new/MyRules609.png",
        },
        {
            id: 9,
            price: "220",
            title: "ROUGH GIRL 617",
            type: "Green",
            image: "/images/items/new/RoughGirl617Green.png",
        },
        {
            id: 10,
            price: "220",
            title: "ROUGH GIRL 617",
            type: "Red",
            image: "/images/items/new/RoughGirl617Red.png",
        },
    ];

    return newItems;
}

export function fetchSportRelatedItems() {
    const newItems = [
        {
            id: 1,
            price: "#",
            title: "Product Title",
            type: "Product Type",
            image: "https://via.placeholder.com/160x170",
        },
        {
            id: 2,
            price: "#",
            title: "Product Title",
            type: "Product Type",
            image: "https://via.placeholder.com/160x170",
        },
        {
            id: 3,
            price: "#",
            title: "Product Title",
            type: "Product Type",
            image: "https://via.placeholder.com/160x170",
        },
        {
            id: 4,
            price: "#",
            title: "Product Title",
            type: "Product Type",
            image: "https://via.placeholder.com/160x170",
        },
    ];

    return newItems;
}

export function fetchAllDepartmentsCaterogies() {
    const subcategories = [
        {
            id: 1,
            name: "Men's Athletic Clothing",
            department: "Sports Apparel",
            icon: "/images/categoryicons/Men'sCloting.png",
            url: "mens-athletic-clothing",
        },
        {
            id: 2,
            name: "Women's Clothing",
            department: "Sports Apparel",
            icon: "/images/categoryicons/Women'sCloting.png",
            url: "womens-clothing",
        },
        {
            id: 3,
            name: "Accessories",
            department: "Sports Apparel",
            icon: "/images/categoryicons/Accessories.png",
            url: "accessories",
        },
        {
            id: 4,
            name: "Proteins, Gainers",
            department: "Sports Supplements",
            icon: "/images/categoryicons/ProteinsGainers.png",
            url: "proteins-gainers",
        },
        {
            id: 5,
            name: "Sport Vitamins",
            department: "Sports Supplements",
            icon: "/images/categoryicons/SportVitamins.png",
            url: "sport-vitamins",
        },
        {
            id: 6,
            name: "Amino Acids",
            department: "Sports Supplements",
            icon: "/images/categoryicons/AminoAcids.png",
            url: "amino-acids",
        },
        {
            id: 7,
            name: "Creatines",
            department: "Sports Supplements",
            icon: "/images/categoryicons/Creatines.png",
            url: "creatines",
        },
        {
            id: 8,
            name: "Fitness Nutrition",
            department: "Sports Supplements",
            icon: "/images/categoryicons/FitnessNutrition.png",
            url: "fitness-nutrition",
        },
    ];

    return subcategories;
}

export function fetchDepartmentCaterogies(department) {
    const subcategories = [];
    switch (department) {
        case "mens-athletic-clothing":
            subcategories.push({
                id: 1,
                name: "Shorts",
                department: "Men's Athletic Clothing",
                icon: "/images/categoryicons/Shorts.png",
                url: "shorts",
            });
            subcategories.push({
                id: 2,
                name: "Tops",
                department: "Men's Athletic Clothing",
                icon: "/images/categoryicons/Tops.png",
                url: "tops",
            });
            break;
        case "womens-clothing":
            subcategories.push({
                id: 3,
                name: "Leggings, Pants",
                department: "Women's Clothing",
                icon: "/images/categoryicons/LegginsPants.png",
                url: "leggings-pants",
            });
            subcategories.push({
                id: 4,
                name: "Activewear Tops",
                department: "Women's Clothing",
                icon: "/images/categoryicons/ActivewearTops.png",
                url: "activewear-tops",
            });
            break;
        case "accessories":
            subcategories.push({
                id: 5,
                name: "Hats & Headwear",
                department: "Accessories",
                icon: "/images/categoryicons/HatsHeadwear.png",
                url: "hats-headwear",
            });
            subcategories.push({
                id: 6,
                name: "Sport Gloves",
                department: "Accessories",
                icon: "/images/categoryicons/SportGloves.png",
                url: "sport-gloves",
            });
            subcategories.push({
                id: 7,
                name: "Bags",
                department: "Accessories",
                icon: "/images/categoryicons/Bags.png",
                url: "bags",
            });
            subcategories.push({
                id: 8,
                name: "Bottles, Shakers",
                department: "Accessories",
                icon: "/images/categoryicons/BottlesShakers.png",
                url: "bottles-shakers",
            });
            subcategories.push({
                id: 9,
                name: "Fitness Equipment, Gear",
                department: "Accessories",
                icon: "/images/categoryicons/FitnessEquipmentGear.png",
                url: "fitness-equipment-gear",
            });
            break;
        case "proteins-gainers":
            subcategories.push({
                id: 10,
                name: "Proteins",
                department: "Proteins, Gainers",
                icon: "/images/categoryicons/Proteins.png",
                url: "proteins",
            });
            subcategories.push({
                id: 11,
                name: "Gainers",
                department: "Proteins, Gainers",
                icon: "/images/categoryicons/Gainers.png",
                url: "gainers",
            });
            break;
        case "amino-acids":
            subcategories.push({
                id: 12,
                name: "Branched Chain Amino Acids",
                department: "Amino Acids",
                icon: "/images/categoryicons/BranchedChainAminoAcids.png",
                url: "branched-chain-amino-acids",
            });
            subcategories.push({
                id: 13,
                name: "Mono-amino acid",
                department: "Amino Acids",
                icon: "/images/categoryicons/MonoAminoAcid.png",
                url: "mono-amino-acid",
            });
            break;
        case "creatines":
            subcategories.push({
                id: 14,
                name: "Creatines",
                department: "Creatines",
                icon: "/images/categoryicons/Creatines2.png",
                url: "creatines",
            });
            break;
        case "fitness-nutrition":
            subcategories.push({
                id: 15,
                name: "Bakery",
                department: "Fitness Nutrition",
                icon: "/images/categoryicons/Bakery.png",
                url: "bakery",
            });
            subcategories.push({
                id: 16,
                name: "Chips",
                department: "Fitness Nutrition",
                icon: "/images/categoryicons/Chips.png",
                url: "chips",
            });
            subcategories.push({
                id: 17,
                name: "Sweets",
                department: "Fitness Nutrition",
                icon: "/images/categoryicons/Sweets.png",
                url: "sweets",
            });
            subcategories.push({
                id: 18,
                name: "Flour, Porridge",
                department: "Fitness Nutrition",
                icon: "/images/categoryicons/FlourPorridge.png",
                url: "flour-porridge",
            });
            subcategories.push({
                id: 19,
                name: "Jam, Sirops",
                department: "Fitness Nutrition",
                icon: "/images/categoryicons/JamSirops.png",
                url: "jam-sirops",
            });
            subcategories.push({
                id: 20,
                name: "Sauces, Creams",
                department: "Fitness Nutrition",
                icon: "/images/categoryicons/SaucesCreams.png",
                url: "sauces-creams",
            });
            break;
        default:
            break;
    }
    return subcategories;
}

export function fetchItemsByDepartment(department) {
    const items = [];
    switch (department) {
        case "mens-athletic-clothing":
            items.push({
                id: 7,
                title: "BB Dumbbell T-Back",
                brand: "Better Bodies",
                category: "Tops",
                department: "Men's Athletic Clothing",
                price: "160",
                type: "Black/Red",
                image: "/images/items/new/BBDumbbellT-Back.png",
            });
            break;
        case "womens-clothing":
            items.push({
                id: 8,
                title: "MY RULES 609",
                brand: "Nebbia",
                category: "Leggings, Pants",
                department: "Women's Clothing",
                price: "280",
                type: "Black",
                image: "/images/items/new/MyRules609.png",
            });
            items.push({
                id: 9,
                title: "ROUGH GIRL 617",
                brand: "Nebbia",
                category: "Activewear Tops",
                department: "Women's Clothing",
                price: "220",
                type: "Green",
                image: "/images/items/new/RoughGirl617Green.png",
            });
            items.push({
                id: 10,
                title: "ROUGH GIRL 617",
                brand: "Nebbia",
                category: "Activewear Tops",
                department: "Women's Clothing",
                price: "220",
                type: "Red",
                image: "/images/items/new/RoughGirl617Red.png",
            });
            break;
        case "accessories":
            items.push({
                id: 11,
                title: "GW Water Jug 2.2 L Black",
                brand: "Gorilla Wear",
                category: "Bottles, Shakers",
                department: "Accessories",
                price: "50",
                type: "Black",
                image: "/images/items/new/GWWaterJug2.2LBlack.png",
            });
            items.push({
                id: 12,
                title: "GW Functional Gym Towel Black/Red",
                brand: "Gorilla Wear",
                category: "Accessories",
                department: "Accessories",
                price: "80",
                type: "Black/Red",
                image: "/images/items/new/GWFunctionalGymTowelBlackRed.png",
            });
            break;
        case "proteins-gainers":
            items.push({
                id: 3,
                title: "Whey Concentrate 5Lb",
                brand: "ProSupps",
                category: "Proteins",
                department: "Proteins, Gainers",
                price: "450",
                type: "Vanilla",
                image: "/images/items/new/WheyConcentrate5LbVanilla.png",
            });
            items.push({
                id: 3,
                title: "Whey Concentrate 5Lb",
                brand: "ProSupps",
                category: "Proteins",
                department: "Proteins, Gainers",
                price: "450",
                type: "Chocolate Ice Cream",
                image: "/images/items/new/WheyConcentrate5LbChocolateIceCream.png",
            });
            break;
        case "amino-acids":
            break;
        case "creatines":
            break;
        case "fitness-nutrition":
            items.push({
                id: 1,
                title: "Protein Muesli 30g",
                brand: "BioTechUSA",
                category: "Functional Food",
                department: "Fitness Nutrition",
                price: "7",
                type: "Hazelnut",
                image: "/images/items/new/ProteinMuesli30gHazelnut.png",
            });
            items.push({
                id: 1,
                title: "Protein Muesli 30g",
                brand: "BioTechUSA",
                category: "Functional Food",
                department: "Fitness Nutrition",
                price: "7",
                type: "Apricot",
                image: "/images/items/new/ProteinMuesli30gApricot.png",
            });
            items.push({
                id: 5,
                title: "Crispy Protein Bar 40g",
                brand: "BioTechUSA",
                category: "Snacks",
                department: "Fitness Nutrition",
                price: "9",
                type: "Milky",
                image: "/images/items/new/CrispyProteinBar40g.png",
            });
            break;
        default:
            break;
    }

    return items;
}

export function fetchItemsByCategory(category) {
    const items = [];
    switch (category) {
        case "shorts":
            break;
        case "tops":
            items.push({
                id: 7,
                title: "BB Dumbbell T-Back",
                brand: "Better Bodies",
                category: "Tops",
                department: "Men's Athletic Clothing",
                price: "160",
                type: "Black/Red",
                image: "/images/items/new/BBDumbbellT-Back.png",
            });
            break;
        case "leggings-pants":
            items.push({
                id: 8,
                title: "MY RULES 609",
                brand: "Nebbia",
                category: "Leggings, Pants",
                department: "Women's Clothing",
                price: "280",
                type: "Black",
                image: "/images/items/new/MyRules609.png",
            });
            break;
        case "activewear-tops":
            items.push({
                id: 9,
                title: "ROUGH GIRL 617",
                brand: "Nebbia",
                category: "Activewear Tops",
                department: "Women's Clothing",
                price: "220",
                type: "Green",
                image: "/images/items/new/RoughGirl617Green.png",
            });
            items.push({
                id: 10,
                title: "ROUGH GIRL 617",
                brand: "Nebbia",
                category: "Activewear Tops",
                department: "Women's Clothing",
                price: "220",
                type: "Red",
                image: "/images/items/new/RoughGirl617Red.png",
            });
            break;
        case "hats-headwear":
            break;
        case "sport-gloves":
            break;
        case "bags":
            break;
        case "bottles-shakers":
            break;
        case "fitness-equipment-gear":
            break;
        case "proteins":
            items.push({
                id: 3,
                title: "Whey Concentrate 5Lb",
                brand: "ProSupps",
                category: "Proteins",
                department: "Proteins, Gainers",
                price: "450",
                type: "Vanilla",
                image: "/images/items/new/WheyConcentrate5LbVanilla.png",
            });
            items.push({
                id: 3,
                title: "Whey Concentrate 5Lb",
                brand: "ProSupps",
                category: "Proteins",
                department: "Proteins, Gainers",
                price: "450",
                type: "Chocolate Ice Cream",
                image: "/images/items/new/WheyConcentrate5LbChocolateIceCream.png",
            });
            break;
        case "gainers":
            break;
        case "branched-chain-amino-acids":
            break;
        case "mono-amino-acid":
            break;
        case "creatines":
            break;
        case "bakery":
            break;
        case "chips":
            break;
        case "sweets":
            break;
        case "flour-porridge":
            break;
        case "jam-sirops":
            break;
        case "snacks":
            items.push({
                id: 1,
                title: "Protein Muesli 30g",
                brand: "BioTechUSA",
                category: "Functional Food",
                department: "Fitness Nutrition",
                price: "7",
                type: "Hazelnut",
                image: "/images/items/new/ProteinMuesli30gHazelnut.png",
            });
            items.push({
                id: 1,
                title: "Protein Muesli 30g",
                brand: "BioTechUSA",
                category: "Functional Food",
                department: "Fitness Nutrition",
                price: "7",
                type: "Apricot",
                image: "/images/items/new/ProteinMuesli30gApricot.png",
            });
            items.push({
                id: 5,
                title: "Crispy Protein Bar 40g",
                brand: "BioTechUSA",
                category: "Snacks",
                department: "Fitness Nutrition",
                price: "9",
                type: "Milky",
                image: "/images/items/new/CrispyProteinBar40g.png",
            });
            break;
        default:
            break;
    }

    return items;
}

export function fetchItemsBanner(category) {
    const campaign = [];

    switch (category) {
        case "mens-athletic-clothing":
            campaign.push({
                id: 1,
                title: "Get Personalized Advice",
                description:
                    "Join our live stream with brand's expert and get personalized advice in less than a minute!",
                buttonText: "Join Stream with Expert",
                image: "https://via.placeholder.com/1000x500",
                eyezon: {
                    id: 226,
                    title: "Men's Athletic Clothing Department",
                },
            });
            break;
        case "womens-clothing":
            campaign.push({
                id: 2,
                title: "Get Personalized Advice",
                description:
                    "Join our live stream with brand's expert and get personalized advice in less than a minute!",
                buttonText: "Join Stream with Expert",
                image: "https://via.placeholder.com/1000x500",
                eyezon: {
                    id: 225,
                    title: "Women's Clothing Department",
                },
            });
            break;
        case "accessories":
            campaign.push({
                id: 3,
                title: "Get Personalized Advice",
                description:
                    "Join our live stream with brand's expert and get personalized advice in less than a minute!",
                buttonText: "Join Stream with Expert",
                image: "https://via.placeholder.com/1000x500",
                eyezon: {
                    id: 226,
                    title: "Accessories Department",
                },
            });
            break;
        case "proteins-gainers":
            campaign.push({
                id: 4,
                title: "Get Personalized Nutrition Advice",
                description:
                    "Join our live stream with a nutrition expert on October 10th and 11th, and get personalized advice in less than a minute!",
                buttonText: "Join Stream with Expert",
                image: "https://via.placeholder.com/1000x500",
                eyezon: {
                    id: 226,
                    title: "Proteins, Gainers Department",
                },
            });
            break;
        case "amino-acids":
            campaign.push({
                id: 5,
                title: "Get Personalized Nutrition Advice",
                description:
                    "Join our live stream with a nutrition expert on October 10th and 11th, and get personalized advice in less than a minute!",
                buttonText: "Join Stream with Expert",
                image: "https://via.placeholder.com/1000x500",
                eyezon: {
                    id: 226,
                    title: "Amino Acids",
                },
            });
            break;
        case "creatines":
            campaign.push({
                id: 6,
                title: "Get Personalized Nutrition Advice",
                description:
                    "Join our live stream with a nutrition expert on October 10th and 11th, and get personalized advice in less than a minute!",
                buttonText: "Join Stream with Expert",
                image: "https://via.placeholder.com/1000x500",
                eyezon: {
                    id: 226,
                    title: "Creatines",
                },
            });
            break;
        case "fitness-nutrition":
            campaign.push({
                id: 7,
                title: "Get Personalized Nutrition Advice",
                description:
                    "Join our live stream with a nutrition expert on October 10th and 11th, and get personalized advice in less than a minute!",
                buttonText: "Join Stream with Expert",
                image: "https://via.placeholder.com/1000x500",
                eyezon: {
                    id: 226,
                    title: "Fitness Nutrition",
                },
            });
            break;
        default:
            break;
    }
    return campaign;
}

export function fetchFiltersByCategory(category) {
    const filters = [
        {
            name: "Filter Name",
        },
    ];
    return filters;
}

export function fetchItemById(itemId) {
    switch (itemId) {
        case "8":
            return {
                id: 8,
                title: "MY RULES 609",
                brand: "Nebbia",
                category: "Leggings, Pants",
                department: "Women's Clothing",
                price: "280",
                type: "Black",
                variants: [
                    {
                        id: 8,
                        type: "color",
                        value: "Black",
                        label: "Black",
                    },
                ],
                description: "Booty shaping leggings MY RULES 609 ",
                details: [
                    {
                        name: "Parameter 1",
                        value: "Test 1",
                    },
                    {
                        name: "Parameter 2",
                        value: "Test 2",
                    },
                    {
                        name: "Parameter 3",
                        value: "Test 3",
                    },
                ],
                image: [
                    { imageUrl: "/images/items/new/MyRules609.png", alt: "" },
                ],
                eyezon: {
                    id: "227",
                    title: "Nebbia MY RULES 609 (Leggings, Pants)",
                },
                seller: {
                    id: "0001",
                    displayName: "Bodymania",
                },
            };
            break;
        case "9":
            return {
                id: 9,
                title: "ROUGH GIRL 617",
                brand: "Nebbia",
                category: "Activewear Tops",
                department: "Women's Clothing",
                price: "220",
                type: "Green",
                variants: [
                    {
                        id: 9,
                        type: "color",
                        value: "Green",
                        label: "Green",
                    },
                    {
                        id: 10,
                        type: "color",
                        value: "Red",
                        label: "Red",
                    },
                ],
                description: "Crop tank top ROUGH GIRL 617",
                details: [
                    {
                        name: "Parameter 1",
                        value: "Test 1",
                    },
                    {
                        name: "Parameter 2",
                        value: "Test 2",
                    },
                    {
                        name: "Parameter 3",
                        value: "Test 3",
                    },
                ],
                image: [
                    {
                        imageUrl: "/images/items/new/RoughGirl617Green.png",
                        alt: "",
                    },
                ],
                eyezon: {
                    id: "227",
                    title: "Nebbia ROUGH GIRL 617 (Activewear Tops)",
                },
                seller: {
                    id: "0001",
                    displayName: "Bodymania",
                },
            };
            break;
        case "10":
            return {
                id: 10,
                title: "ROUGH GIRL 617",
                brand: "Nebbia",
                category: "Activewear Tops",
                department: "Women's Clothing",
                price: "220",
                type: "Red",
                variants: [
                    {
                        id: 9,
                        type: "color",
                        value: "Green",
                        label: "Green",
                    },
                    {
                        id: 10,
                        type: "color",
                        value: "Red",
                        label: "Red",
                    },
                ],
                description: "Crop tank top ROUGH GIRL 617",
                details: [
                    {
                        name: "Parameter 1",
                        value: "Test 1",
                    },
                    {
                        name: "Parameter 2",
                        value: "Test 2",
                    },
                    {
                        name: "Parameter 3",
                        value: "Test 3",
                    },
                ],
                image: [
                    {
                        imageUrl: "/images/items/new/RoughGirl617Red.png",
                        alt: "",
                    },
                ],
                eyezon: {
                    id: "227",
                    title: "Nebbia ROUGH GIRL 617 (Activewear Tops)",
                },
                seller: {
                    id: "0001",
                    displayName: "Bodymania",
                },
            };
            break;

        default:
            break;
    }
    const item = {
        id: "0001",
        title: "Test Title",
        brand: "Test Brand",
        price: "999",
        variants: [
            {
                id: "0001",
                type: "color",
                value: "Black",
                label: "Black",
            },
            {
                id: "0002",
                type: "color",
                value: "White",
                label: "White",
            },
            {
                id: "0003",
                type: "color",
                value: "Brown",
                label: "Brown",
            },
        ],
        description: "Test description",
        details: [
            {
                name: "Parameter 1",
                value: "Test 1",
            },
            {
                name: "Parameter 2",
                value: "Test 2",
            },
            {
                name: "Parameter 3",
                value: "Test 3",
            },
        ],
        image: [
            {
                imageUrl: "https://via.placeholder.com/256x256?text=image1",
                alt: "",
            },
            {
                imageUrl: "https://via.placeholder.com/256x256?text=image2",
                alt: "",
            },
            {
                imageUrl: "https://via.placeholder.com/256x256?text=image3",
                alt: "",
            },
            {
                imageUrl: "https://via.placeholder.com/256x256?text=image4",
                alt: "",
            },
            {
                imageUrl: "https://via.placeholder.com/256x256?text=image5",
                alt: "",
            },
            {
                imageUrl: "https://via.placeholder.com/256x256?text=image6",
                alt: "",
            },
            {
                imageUrl: "https://via.placeholder.com/256x256?text=image7",
                alt: "",
            },
            {
                imageUrl: "https://via.placeholder.com/256x256?text=image8",
                alt: "",
            },
            {
                imageUrl: "https://via.placeholder.com/256x256?text=image9",
                alt: "",
            },
            {
                imageUrl: "https://via.placeholder.com/256x256?text=image10",
                alt: "",
            },
            {
                imageUrl: "https://via.placeholder.com/256x256?text=image11",
                alt: "",
            },
        ],
        eyezon: {
            id: "227",
            target: "25555",
            title: "Test Bag",
        },
        seller: {
            id: "0001",
            displayName: "Bodymania",
        },
    };
    return item;
}

export function fetchCuratedCollection() {
    const collections = [
        {
            id: 1,
            title: "Health Suplements",
            link: "/url",
            image: "/images/curatedcollection/health_suplements.png",
        },
        {
            id: 2,
            title: "Proteins",
            link: "/url",
            image: "/images/curatedcollection/proteins.png",
        },
        {
            id: 3,
            title: "Accessories",
            link: "/url",
            image: "/images/curatedcollection/accessories.png",
        },
        {
            id: 4,
            title: "Bulking",
            link: "/url",
            image: "/images/curatedcollection/bulking.png",
        },
        {
            id: 5,
            title: "Loosing Weight",
            link: "/url",
            image: "/images/curatedcollection/loosing_weight.png",
        },
        {
            id: 6,
            title: "Fitness Nutrition",
            link: "/url",
            image: "/images/curatedcollection/fitness_nutrition.png",
        },
    ];

    return collections;
}

export function fetchBrands() {
    const brands = [
        {
            id: 1,
            image: "/images/brands/nebbia.png",
            name: "Nebbia",
            link: "/url",
        },
        {
            id: 2,
            image: "/images/brands/gorilla_wear.png",
            name: "Gorilla Wear",
            link: "/url",
        },
        {
            id: 3,
            image: "/images/brands/better_bodies.png",
            name: "Better Bodies",
            link: "/url",
        },
        {
            id: 4,
            image: "/images/brands/biotechusa.png",
            name: "BioTechUsa",
            link: "/url",
        },
        {
            id: 5,
            image: "/images/brands/scitec_nutrition.png",
            name: "SCITEC",
            link: "/url",
        },
        {
            id: 6,
            image: "/images/brands/gat_sport.png",
            name: "GAT",
            link: "/url",
        },
        {
            id: 7,
            image: "/images/brands/prosupps.png",
            name: "ProSupps",
            link: "/url",
        },
    ];

    return brands;
}

export function fetchSuggestions() {
    const suggestions = [
        { key: "1", name: "Accessories", url: "/sport/accessories" },
        {
            key: "2",
            name: "Sport Gloves",
            url: "/sport/accessories/sport-gloves",
        },
        {
            key: "3",
            name: "Fitness Nutrition",
            url: "/sport/fitness-nutrition",
        },
        { key: "4", name: "Proteins, Gainers", url: "/sport/proteins-gainers" },
        {
            key: "5",
            name: "Bottles, Shakers",
            url: "/sport/accessories/bottles-shakers",
        },
        {
            key: "6",
            name: "Sports Supplements",
            url: "/sport/sports-supplements",
        },
    ];

    return suggestions;
}

export function fetchCategoryNameByPath(path) {
    switch (path) {
        case "mens-athletic-clothing":
            return "Men's Athletic Clothing";
        case "womens-clothing":
            return "Women's Clothing";
        case "accessories":
            return "Accessories";
        case "proteins-gainers":
            return "Proteins, Gainers";
        case "sport-vitamins":
            return "Sport Vitamins";
        case "amino-acids":
            return "Amino Acids";
        case "creatines":
            return "Creatines";
        case "fitness-nutrition":
            return "Fitness Nutrition";
        case "shorts":
            return "Shorts";
        case "tops":
            return "Tops";
        case "leggings-pants":
            return "Leggings, Pants";
        case "activewear-tops":
            return "Activewear Tops";
        case "hats-headwear":
            return "Hats & Headwear";
        case "sport-gloves":
            return "Sport Gloves";
        case "bags":
            return "Bags";
        case "bottles-shakers":
            return "Bottles, Shakers";
        case "fitness-equipment-gear":
            return "Fitness Equipment, Gear";
        case "proteins":
            return "Proteins";
        case "gainers":
            return "Gainers";
        case "branched-chain-amino-acids":
            return "Branched Chain Amino Acids";
        case "mono-amino-acid":
            return "Mono-amino acid";
        case "creatines":
            return "Creatines";
        case "bakery":
            return "Bakery";
        case "chips":
            return "Chips";
        case "sweets":
            return "Sweets";
        case "flour-porridge":
            return "Flour, Porridge";
        case "jam-sirops":
            return "Jam, Sirops";
        case "sauces-creams":
            return "Sauces, Creams";
        case "sport":
            return "Lidabro Sport";
        default:
            return;
    }
}

export function fetchPathByCategoryName(category) {
    switch (category) {
        case "Men's Athletic Clothing":
            return "mens-athletic-clothing";
        case "Women's Clothing":
            return "womens-clothing";
        case "Accessories":
            return "accessories";
        case "Proteins, Gainers":
            return "proteins-gainers";
        case "Sport Vitamins":
            return "sport-vitamins";
        case "Amino Acids":
            return "amino-acids";
        case "Creatines":
            return "creatines";
        case "Fitness Nutrition":
            return "fitness-nutrition";
        case "Shorts":
            return "shorts";
        case "Tops":
            return "tops";
        case "Leggings, Pants":
            return "leggings-pants";
        case "Activewear Tops":
            return "activewear-tops";
        case "Hats & Headwear":
            return "hats-headwear";
        case "Sport Gloves":
            return "sport-gloves";
        case "Bags":
            return "bags";
        case "Bottles, Shakers":
            return "bottles-shakers";
        case "Fitness Equipment, Gear":
            return "fitness-equipment-gear";
        case "Proteins":
            return "proteins";
        case "Gainers":
            return "gainers";
        case "Branched Chain Amino Acids":
            return "branched-chain-amino-acids";
        case "Mono-amino acid":
            return "mono-amino-acid";
        case "Creatines":
            return "creatines";
        case "Bakery":
            return "bakery";
        case "Chips":
            return "chips";
        case "Sweets":
            return "sweets";
        case "Flour, Porridge":
            return "flour-porridge";
        case "Jam, Sirops":
            return "jam-sirops";
        case "Sauces, Creams":
            return "sauces-creams";
        case "Lidabro Sport":
            return "sport";
        default:
            return;
    }
}
