import ButtonIcon from "../Controls/ButtonIcon";
import { MoreVerticalSVG } from "../../images/SVGAssets";

function BasketListItem({ item, amount }) {
    //TODO: Change discount
    const discount = 100;

    return (
        <>
            <div className="w-full flex items-center px-[10px] py-[5px] border-b border-gray-lidabro-border-3">
                <div className="flex w-[50px] sm:w-[100px] h-[50px] sm:h-[100px]">
                    <img
                        className="w-full object-cover"
                        src={item.image[0].imageUrl}
                        alt={item.image[0].alt}
                    />
                </div>
                <div className="w-full flex pl-3 py-3 gap-3 items-center">
                    <div className="flex flex-col py-3 gap-[6px] grow">
                        <div className="flex flex-col">
                            <p className="font-secondary text-xs font-medium leading-[18px] text-nowrap text-gray-lidabro-select-remove">
                                Product Type
                            </p>
                            <p className="font-secondary text-xs font-semibold leading-[18px] text-nowrap text-gray-lidabro-text">
                                {item.title}
                            </p>
                        </div>
                        <div className="flex gap-3">
                            {item.variants.map((variant) => (
                                <p
                                    key={variant.id}
                                    className="font-secondary text-xxs font-medium leading-[18px] text-nowrap text-gray-lidabro-select-remove"
                                >
                                    {variant.label}
                                </p>
                            ))}
                        </div>
                    </div>
                    <div className="flex sm:hidden items-center justify-end gap-[6px]">
                        <p className="font-secondary text-xs font-semibold leading-[18px] text-nowrap">
                            ₪ {item.price}
                        </p>
                        <p className="font-secondary text-xs font-semibold leading-[18px] text-nowrap">
                            x{amount}
                        </p>
                        <ButtonIcon icon={<MoreVerticalSVG />} />
                    </div>
                    <div className="hidden sm:flex flex-col gap-[5px]">
                        <div className="flex items-center gap-[6px]">
                            <p className="font-secondary text-[11px] font-medium leading-6 line-through text-gray-lidabro-select-remove">
                                ₪{item.price - discount}
                            </p>
                            <p className="font-secondary text-lg font-semibold text-black-lidabro-review">
                                ₪{item.price}
                            </p>
                            <p className="font-secondary text-lg font-semibold text-black-lidabro-review">
                                x{amount}
                            </p>
                        </div>
                        <p className="font-secondary text-xs font-normal leading-[18px] text-gray-lidabro-border-2">
                            {discount}₪ Discount
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BasketListItem;
