import { useState } from "react";
import { PlusSVG, MinusSVG } from "../../images/SVGAssets";

function ItemDetailsSport({ item, collapsable }) {
    const [opened, SetOpened] = useState(collapsable ? false : true);

    return (
        <>
            <div
                className={`flex items-center justify-between p-[6px] ${
                    collapsable
                        ? "cursor-pointer border-b-[.8px] border-gray-lidabro-border-3"
                        : ""
                }`}
                onClick={() => {
                    if (collapsable) {
                        SetOpened(!opened);
                    }
                }}
            >
                <p className="font-secondary text-sm md:text-base font-medium md:font-semibold leading-5 md:leading-6 text-gray-lidabro-breadcrumb md:text-gray-lidabro-breadcrumb-bold">
                    Details
                </p>
                {collapsable ? (
                    <div>{opened ? <MinusSVG /> : <PlusSVG />}</div>
                ) : null}
            </div>
            {opened ? (
                <div
                    className={`flex flex-col  ${
                        collapsable
                            ? "border-b-[.8px] border-gray-lidabro-border-3"
                            : ""
                    }`}
                >
                    {item.details.map((detail) => (
                        <div
                            className="flex items-center justify-between px-[6px] py-2"
                            key={detail.id}
                        >
                            <p className="font-secondary text-xs md:text-base font-normal leading-[18px] md:leading-6 text-gray-lidabro-breadcrumb md:text-gray-lidabro-breadcrumb-bold">
                                {detail.name}
                            </p>
                            <p className="font-secondary text-xs md:text-base font-medium leading-[18px] md:leading-6 text-gray-lidabro-breadcrumb md:text-gray-lidabro-breadcrumb-bold">
                                {detail.value}
                            </p>
                        </div>
                    ))}
                </div>
            ) : null}
        </>
    );
}

export default ItemDetailsSport;
